import 'react-loading-skeleton/dist/skeleton.css'
import React from 'react'
import {SkeletonTheme} from 'react-loading-skeleton'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import Home from './pages/Home'

function App() {
    return (
        <SkeletonTheme baseColor="#c1c1c1" highlightColor="#dfdfdf">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </SkeletonTheme>
    )
}

export default App
