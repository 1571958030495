import React from 'react'

import {Container} from './style'

const Heading = ({ className, title, subtitle, color }: any) => {
    return (
        <Container>
            <div className={`line bg-${color}`} />
            {subtitle && <div className={`subtitle heading4 color-${color}`}>{subtitle}</div>}
            <div className={`${className} color-${color} font`}>{title}</div>
        </Container>
    )
}

export default Heading
