import React from 'react'
import {Container} from './style'

const Cover = () => {
    return (
        <Container>
            <div className="background">
                <img src="/assets/images/palestra.jpg" />
                <section className="wrapper">
                    <h2>Solicite sua</h2>
                    <h1>Certificação Internacional de Proficiência em Inglês</h1>
                    <img src="/assets/images/fdc.png" alt="Fundação Dom Cabral" />
                    <p>
                        Obtenha seu certificado de inglês para inscrição em um dos
                        programas da <strong>FDC (Fundação Dom Cabral)</strong>
                    </p>
                </section>
            </div>
        </Container>
    )
}

export default Cover
