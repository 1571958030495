import React from 'react'
import {Container} from './style'
import Heading from "../../../../components/Heading";

const WhatIsIt = () => {
    return (
        <Container>
            <section className="wrapper">
                <div>
                    <img src="/assets/images/fdc-img1.png" />
                </div>
                <div>
                    <Heading className="heading2" title={'O que é?'} color={'white'} />
                    <div className={'text'}>
                        <p>
                            O Certificado Internacional de Proficiência em Inglês é um dos pré-requisitos necessários
                            para ingressar em um dos programas da <strong>FDC (Fundação Dom Cabral)</strong>.
                        </p>
                        <p>
                            Essa Certificação é realizada em parceria com a <strong>PLS (Paradigm Language
                            Support)</strong>, consultoria independente e especializada em treinamento em idiomas com
                            mais de 28 anos oferecendo soluções estratégicas e melhores práticas para corporações e
                            profissionais que desejam investir na internacionalização e capacitação linguística em um
                            segundo idioma.
                        </p>
                    </div>
                </div>
                <div className="line-white"></div>
            </section>
        </Container>
    )
}

export default WhatIsIt
