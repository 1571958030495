import styled from 'styled-components'

export const Container = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;

    img {
        height: 90px;
    }

    address {
        margin: 15px 0;
        text-align: center;
        font-style: normal;
    }

    .icons {
        display: flex;
        margin-top: 15px;

        & img {
            width: 48px;
            height: 48px;

            &.whatsapp {
                margin-top: 5px;
                margin-left: 6px;
                height: 37px;
                width: 37px;
            }
        }
    }

    @media (max-width: 980px) {
        address {
            font-size: 9px;
        }
    }
`
