import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background: var(--blue2);
  padding: 40px 0;

  @media (max-width: 487px) {
    & .wrapper {
      padding: 15px !important;

      & section {
        grid-template-columns: 100% !important;
      }

      & .valores {
        flex-direction: column !important;

        & .valor, .dados-pagamento, .importante {
          width: 100% !important;
        }

        & .valor {
          & span {
            font-size: 35px !important;
          }
        }
      }


      & .right {
        display: none !important;
      }
    }
  }

  & .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    color: white;

    & section {
      display: grid;
      grid-template-columns: 50% 50%;
      margin-top: 25px;
    }

    & img {
      width: 466px;
    }

    & .right {
      text-align: right;
    }

    & ul {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & li {
        display: flex;
        align-items: center;
        gap: 15px;

        & p {
          font-size: 17px;
          line-height: 18px;
        }
      }
    }

    & .num {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      color: var(--blue2);
      width: 45px;
      height: 45px;
      box-sizing: border-box;
      border-radius: 50%;
      font-weight: bold;
    }

    & footer {
      margin: 25px 0 0;

      & p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 15px;
      }

      & img {
        width: 30px;
        height: 30px;
      }
    }

    & .valores {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      align-items: flex-start;

      & .valor {
        display: flex;
        flex-direction: column;
        background: white;
        color: var(--blue2);
        font-weight: bolder;
        font-size: 20px;
        text-align: center;
        padding: 20px;

        & span {
          font-size: 40px;
        }
      }

      & .dados-pagamento {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 35%;

        & .label {
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          font-size: 13px;
          color: var(--blue2);
          font-weight: bold;
          padding: 10px;
          text-transform: uppercase;

        }

        & .dados {
          font-size: 15px;
          text-align: center;

          & h4 {
            font-size: 20px;
          }
        }
      }

      & .importante {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 35%;

        & .label {
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          font-size: 13px;
          color: var(--blue2);
          font-weight: bold;
          padding: 10px;
          text-transform: uppercase;
        }

        & .dados {
          font-size: 17px;
          text-align: center;

          & strong {
            font-size: 20px;
          }

          & h4 {
            font-size: 18px;
          }
        }
      }
    }
  }

`
