import React from 'react'
import {useLocation} from 'react-router-dom'

import {Container} from './style'

const Header = (props: any) => {
    const { moreInfo } = props
    const location = useLocation()
    const { pathname } = location

    const isActive = (loc: any) => {
        return loc === pathname
    }

    return (
        <Container>
            <div className="wrapper">
                <div className="logo">
                    <a href="http://pls.com.br">
                        <img src="/assets/images/paradigm-logo.png" alt="Paradigm Language Support" />
                    </a>
                </div>
            </div>
        </Container>
    )
}

export default Header
