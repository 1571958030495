import React from 'react'

import {Container} from './style'

const Footer = () => {
    return (
        <Container>
            <img src="/assets/images/paradigm-logo.png" alt="Paradigm" />
            <a href="http://www.pls.com.br">www.pls.com.br</a>
            <div className="icons">
                <a href="https://www.instagram.com/paradigm_ls/" target="_blank">
                    <img src="/assets/images/icon-instagram.png" alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com/company/paradigm-language-support/" target="_blank">
                    <img src="/assets/images/icon-linkedin.png" alt="LinkedIn" />
                </a>
                <a href="https://wa.me/message/ML3NTCANXBPKA1" target="_blank">
                    <img className="whatsapp" src="/assets/images/icon-whatsapp.png" alt="Whatsapp" />
                </a>
            </div>
            <address>
                Rua Carmo do Rio Verde, 241 - 4º andar - Santo Amaro - São Paulo - Brazil <br />
                +55 (11) 2182.0700 - <a href="mailto:pls@pls.com.br">pls@pls.com.br</a>
            </address>
        </Container>
    )
}

export default Footer
