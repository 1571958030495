import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 487px) {
    & .wrapper {
      padding: 15px !important;
      grid-template-columns: 100% !important;

      & .items {
        gap: 25px !important;
      }

    }

    & .right {
      display: none !important;
    }
  }

  & .wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    flex: 1;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 40px 0;

    & > div {
      flex: 1;
    }

    & img {
      width: 466px;
    }

    & .items {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    & .item {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    & .right {
      text-align: right;
    }

  }
`