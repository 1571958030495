import React from 'react'
import {Container} from './style'
import Heading from "../../../../components/Heading";

const GuideLines = () => {
    return (
        <Container>
            <div className="wrapper">
                <Heading className="heading2" title={'Orientações para obter a certificação'} color={'white'} />
                <section>
                    <div>
                        <ul>
                            <li>
                                <div>
                                    <span className={'num'}>1</span>
                                </div>
                                <p>
                                    <strong>PRAZO</strong> - Após a realização das 2 etapas (teste online e entrevista
                                    com o consultor), o prazo de entrega são de <strong>48 horas (dias úteis).</strong>
                                </p>
                            </li>
                            <li>
                                <div>
                                    <span className={'num'}>2</span>
                                </div>
                                <p>
                                    <strong>CANCELAMENTO</strong> - No caso de cancelamento da entrevista com o
                                    consultor com menos de 24 horas de antecedência, incidirá uma cobrança adicional
                                    de <strong>R$ 55,00</strong>, para obter uma nova data para agendamento de sua
                                    entrevista.
                                </p>
                            </li>
                            <li>
                                <div>
                                    <span className={'num'}>3</span>
                                </div>
                                <p>
                                    <strong>DESISTÊNCIA</strong> - caso tenha feito o pagamento e desistido na obtenção
                                    do certificado (caso não tenha iniciado o processo junto a <strong>Paradigm</strong>),
                                    você poderá solicitar o ressarcimento do valor integral <strong>em até 7
                                    dias</strong>
                                </p>
                            </li>
                            <li>
                                <div>
                                    <span className={'num'}>4</span>
                                </div>
                                <p>
                                    <strong>VALIDADE</strong> - sua certificação é verificada e autenticada pelo Site da
                                    Paradigm, e terá a validade de <strong>1 ano</strong>
                                </p>
                            </li>
                            <li>
                                <div>
                                    <span className={'num'}>5</span>
                                </div>
                                <p>
                                    <strong>COMPROVANTE</strong> - Após o pagamento, favor enviar uma cópia do
                                    comprovante para identificação para o e-mail: <strong>pls@pls.com.br</strong>, com
                                    os seus dados principais (Nome, e-mail, e CPF) e assunto "FDC - Certificação"
                                </p>
                            </li>
                            <li>
                                <div>
                                    <span className={'num'}>6</span>
                                </div>
                                <p>
                                    <strong>INICIO DO PROCESSO</strong> - Após a confirmação do pagamento, você receberá
                                    a senha de acesso para a realização do teste online, e agendamento de sua entrevista
                                    com um consultor em data e horário de sua melhor conveniência.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className={'right'}>
                        <img src="/assets/images/fdc-img3.png" />
                    </div>
                </section>
                <section className="valores">
                    <div className="valor">
                        Valor
                        <span>R$ 320,00</span>
                    </div>
                    <div className="dados-pagamento">
                        <div className="label">Dados para pagamento</div>
                        <div className={'dados'}>
                            <h4>PARADIGM LANGUAGE SUPPORT</h4>
                            <p>
                                <strong>PIX - 71.735.757/0002-52</strong> <br />
                                <strong>ITAÚ:</strong> agencia <strong>0738</strong> c/c <strong>42.261-7</strong> CNPJ <strong>71.735.757/0002-52</strong>
                            </p>
                        </div>
                    </div>
                    <div className="importante">
                        <div className="label">IMPORTANTE</div>
                        <div className="dados">
                            <p>
                                enviar o comprovante para <br />
                                <strong>pls@pls.com.br</strong>
                            </p>
                        </div>
                    </div>
                </section>
                <footer>
                    <p>
                        Em caso de dúvidas, entre em contato pelo Fone/whatsapp (11) 2182-0700
                        <a href="https://wa.me/message/ML3NTCANXBPKA1" target="_blank"><img
                            src="/assets/images/icon-whatsapp.png" alt={'imgZap'} /></a>
                    </p>
                </footer>
            </div>
        </Container>
    )
}

export default GuideLines
