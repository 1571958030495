import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 570px;

  @media (max-width: 487px) {
    height: 320px !important;

    & .background {
      height: 320px !important;

      & > img {
        top: 0 !important;
        width: 160% !important;
      }
    }

    & .wrapper {
      padding: 15px !important;

      & h1 {
        font-size: 1.1rem !important;
      }

      & h2 {
        font-size: 1.2rem !important;
      }

      & p {
        font-size: 0.9rem !important;
      }

      & img {
        width: 70% !important;
      }

      & a {
        width: 120px !important;
        height: 30px !important;
        font-size: 1rem !important;
      }
    }
  }

  & .background {
    display: flex;
    flex: 1;
    overflow: hidden;
    height: 570px;
    position: relative;

    & > img {
      width: 100%;
      position: absolute;
      top: -40%;
      opacity: .3;
      z-index: 1;
    }
  }

  & .wrapper {
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    margin: 0 auto;
    max-width: 1280px;

    & h1 {
      text-transform: uppercase;
      color: #025E83;
    }

    & p {
      margin: 5px 0 10px;
    }

    & img {
      position: relative;
      width: 500px;
      left: -5px;
    }
  }
`
