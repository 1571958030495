import React from 'react'

import {Container} from './style'
import Heading from '../../../../components/Heading'
import Footer from '../../../../layout/Footer'

const BeParadigm = (props: any) => {
    const { background } = props

    const getBackground = () => {
        switch (background) {
            case 'company':
                return '/assets/images/imagem-bepls-emp.jpg'
            default:
                return '/assets/images/mulher-pls.jpeg'
        }
    }

    return (
        <Container style={{ backgroundImage: `url(${getBackground()})` }}>
            <div className="wrapper">
                <div />
                <div className="content">
                    <Heading title="Be Paradigm" className="heading2" color="blue2" />
                    <p>Na Paradigm somos apaixonados por idiomas.</p>
                    <p>
                        Reconhecemos a importância de avaliações de idiomas eficazes em um ambiente multicultural. Esta
                        é uma verdade para tomadores
                        de decisão, membros de equipes e clientes.
                    </p>
                    <p>
                        Com as nossas Certificações, colaboramos com as corporações com uma ferramenta de grande ajuda.
                        Diferencie-se e saia na
                        frente! Este é um fator primordial para aqueles que querem interagir, e se sobressair em um
                        mundo cada dia mais globalizado.
                    </p>
                    <p>Para nós será uma honra apoiá-lo em vencer os desafios que surgem em sua jornada.</p>
                    <p>
                        <strong>"Take care, because We care"</strong> - <em>Paradigm</em>
                    </p>

                    <Footer />
                </div>
            </div>
        </Container>
    )
}

export default BeParadigm
