import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background: #025E83;
  padding: 50px 0;
  position: relative;

  @media (max-width: 487px) {
    padding: 30px 0 !important;

    & .wrapper {
      grid-template-columns: 100% !important;
      margin: 0 15px !important;
      padding-bottom: 10px !important;

      & > div:nth-child(1) {
        display: none !important;
      }

      & .line-white {
        display: none !important;
      }
    }
  }


  & .wrapper {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 1280px;
    margin: 0 auto;
    padding-bottom: 20px;

    & img {
      position: relative;
      width: 466px;
      filter: grayscale(100%);
      z-index: 2;
    }

    & p {
      color: white;
    }

    & .text {
      margin-top: 20px;
      line-height: 22px;
    }

    & .line-white {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 35px;
      background: white;
      height: 150px;
      z-index: 1;
    }
  }
`
