import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6, .heading1, .heading2, .heading4 {
    font-family: var(--font-family-1);
  }

  h1, .heading1 {
    font-size: var(--font-size-h1);
    letter-spacing: -1px;

    @media (max-width: 768px) {
      font-size: calc(var(--font-size-h1) - 25px);
    }
  }

  h2, .heading2 {
    font-size: var(--font-size-h2);
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: calc(var(--font-size-h2) - 17px);
    }
  }

  h4, .heading4 {
    font-size: var(--font-size-h4);

    @media (max-width: 768px) {
      font-size: calc(var(--font-size-h4) - 11px);
    }
  }

  body {
    font-family: var(--font-family-2);
    font-size: var(--font-size);

    @media (max-width: 768px) {
      font-size: calc(var(--font-size) - 2px);
    }
  }

  .color-black {
    color: var(--black);
  }

  .color-white {
    color: var(--white);
  }

  .color-blue2 {
    color: var(--blue2);
  }

  .color-purple {
    color: var(--purple);
  }

  .bg-purple {
    background-color: var(--purple);
  }

  .bg-white {
    background-color: var(--white);
  }

  .bg-gray {
    background-color: var(--gray);
  }

  .bg-blue2 {
    background-color: var(--blue2);
  }

  .bg-gray-light {
    background-color: var(--gray-light);
  }

  .bg-white {
    background-color: var(--white);
  }

  .bg-orange {
    background-color: var(--orange);
  }

  button.button, a.button {
    background-color: var(--purple);
    color: var(--white);
    font-size: var(--font-size);
    border: none;
    padding: 11px 45px;
    text-decoration: none;

    @media (max-width: 768px) {
      padding: 9px 35px;
      font-size: calc(var(--font-size) - 2px);
    }
  }

  input {
    font-size: var(--font-size);
    padding: 5px;
    border: 0;
    outline: 0;
    background-color: var(--gray-light);
  }

  :root {
    --font-family-1: 'League Spartan', sans-serif;
    --font-family-2: 'DM Sans', sans-serif;
    --font-size: 19px;
    --font-size-h1: 35px;
    --font-size-h2: 28px;
    --font-size-h4: 26px;
    --blue: #124e91;
    --blue2: #025E83;
    --blue-light: #00C2CB;
    --black: #101010;
    --white: #ffffff;
    --gray: #dbd7d7;
    --gray-600: #575757;
    --gray-light: #fbfbfb;
    --purple: #640663;
    --orange: #f2ae1a;
    --green-whatsapp: #075e54;
  }
`

export default GlobalStyle
