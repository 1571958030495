import React from 'react'
import {Container} from './style'
import Heading from "../../../../components/Heading";

const HowItWorks = () => {
    return (
        <Container>
            <div className="wrapper">
                <div className={'items'}>
                    <div className={'item'}>
                        <Heading className="heading2" title={'A quem se destina?'} color={'blue2'} />
                        <p>
                            O <strong>FDC - Certificação Internacional de Proficiência em Inglês</strong> é destinado
                            aos participantes em processos seletivos, e em processos de inscrição em um dos programas
                            da <strong>Fundação dom Cabral</strong>
                        </p>
                    </div>
                    <div className={'item'}>
                        <Heading className="heading2" title={'Como funciona?'} color={'blue2'} />
                        <p>
                            O <strong>FDC - Certificação Internacional de Proficiência em Inglês</strong> é composta por
                            uma entrevista por <strong> google meet</strong> com duração de <strong>20 minutos</strong>,
                            e um teste online de 20 questões de compreensão de texto e duração máxima de <strong>25
                            minutos.</strong>
                        </p>
                    </div>
                </div>
                <div className={'right'}>
                    <img src="/assets/images/fdc-img2.png" alt={'imgQuemSeDestina'} />
                </div>
            </div>
        </Container>
    )
}

export default HowItWorks
