import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: left center;
  background-color: var(--gray-light);

  > .wrapper {
    padding: 70px 0;
    display: grid;
    grid-template-columns: 55% 1fr;
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;

        > .content {
            font-size: 16px;

            .heading2 {
                margin-bottom: 25px;
            }

            > p {
                margin-bottom: 15px;
            }
        }
    }

    @media (max-width: 980px) {
        background-image: none !important;

        > .wrapper {
            padding: 25px 0;
            grid-template-columns: 0% 1fr;

            > .content {
                margin: 0 15px;
            }
        }
    }
`
