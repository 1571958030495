import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;

  @media (max-width: 376px) {
    > .line {
      height: 4px !important;
      width: 135px !important;
      margin-bottom: 7px !important;
    }

    > .font {
      font-size: 30px !important;
    }
  }

  > .line {
    height: 5px;
    width: 152px;
    margin-bottom: 10px;
  }

  > .subtitle {
    margin-bottom: 3px;
  }

  > .font {
    font-size: 35px;
    font-weight: bolder;
  }
`
