import styled from 'styled-components'

export const Container = styled.header`
    display: flex;
    width: 100%;
    border-top: 28px solid var(--blue);

    > .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        > .logo {
            display: flex;
            align-items: center;
            height: 136px;
        }

        > .links {
            ul {
                display: flex;
                margin: 0;
                list-style: none;
                gap: 25px;

                > li {
                    a {
                        text-decoration: none;
                        padding: 10px 20px;
                        border-radius: 30px;
                        font-size: calc(var(--font-size) - 2px);
                        background: var(--gray);
                        color: var(--purple);
                    }

                    .active {
                        background: var(--purple);
                        color: var(--white);
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        border-top: 15px solid var(--blue);
        padding: 0 15px;

        > .wrapper {
            display: flex;

            > .logo {
                height: 90px;

                img {
                    height: 60px;
                }
            }

            > .links {
                ul {
                    gap: 15px;

                    > li {
                        span {
                            display: none;
                        }

                        a {
                            padding: 5px 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 980px) {
        > .wrapper {
            display: flex;

            > .logo {
                height: 110px;

                img {
                    height: 80px;
                }
            }
        }
    }

    @media (min-width: 1280px) {
        > .wrapper {
            max-width: 1280px;
            margin: 0 auto;
        }
    }
`
