import React from 'react'

import {Container} from './style'
import Header from '../../layout/Header'
import Cover from "./components/Cover";
import WhatIsIt from "./components/WhatIsIt";
import HowItWorks from "./components/HowItWorks";
import GuideLines from "./components/GuideLines";
import BeParadigm from "./components/BeParadigm";

const Home = () => {
    return (
        <Container>
            <div>
                <div className="wrapper">
                    <Header />
                    <Cover />
                    <WhatIsIt />
                    <HowItWorks />
                    <GuideLines />
                    <BeParadigm />
                </div>
            </div>
        </Container>
    )
}

export default Home
