import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    min-width: 320px;

    > .wrapper {
        width: 100%;
        margin: 0 auto;
    }
`
